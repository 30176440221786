import { useState } from "react";
import OurServices from "./OurServices";
import Product from "./Product";
import { EmailService } from "../Service/EmailService";

export default function Home() {

    const [formData, setFormData] = useState({
        receiver: 'Booking@gangescab.com',
        sender: '',
        contact: '',
        pickupLocation: '',
        destinationLocation: ''
    });

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            sender: formData.sender,
            receiver: formData.receiver,
            message: "Contact: " + formData.contact + "\n Pickup Location: " + formData.pickupLocation + "\n Desination: " + formData.destinationLocation
        }

        await EmailService.sendEmail(data);

        setFormData({
            receiver: 'Booking@gangescab.com',
            sender: '',
            contact: '',
            pickupLocation: '',
            destinationLocation: ''
        });
    };

    return <>

        <div id="intro" className="home-bg-image">
            <div className="container ">
                <div className="row justify-content-center home-container" >
                    <div className="col-12 col-sm-12 col-md-8">
                        <span style={{ fontSize: "xxx-large", color: "white" }}>
                            Now <br />
                            It's easy for you
                            rent a car
                            <br />
                            <span className="blink-me">All UP Cab Service Available!</span>
                        </span>
                    </div>
                    <div className="col-10 col-sm-10 col-md-4 p-0" style={{ backgroundColor: "white", borderRadius: "10px", overflow: "hidden" }}>
                        <div className="form-group d-flex justify-content-center align-items-center flex-column" style={{ backgroundColor: "#ffc107c2", height: "10vh", fontWeight: "700" }}>
                            <div>All UP Cab Service</div>
                            <div className="blink-me"> Book Your Cab Now! </div>
                        </div>
                        <form className="py-2 px-4 d-flex flex-column" style={{ gap: "3vh" }} onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="sender">Your name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="sender"
                                    placeholder="Enter your name"
                                    value={formData.sender}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="contact">Contact</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="contact"
                                    placeholder="Contact Number"
                                    value={formData.contact}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="pickupLocation">Pickup Location</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="pickupLocation"
                                    placeholder="Enter Pickup Location"
                                    value={formData.pickupLocation}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="destinationLocation">Destination Location</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="destinationLocation"
                                    placeholder="Enter Destination Location"
                                    value={formData.destinationLocation}
                                    onChange={handleChange}
                                />
                            </div>
                            <button onClick={handleSubmit} className="btn btn-primary">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <OurServices />
        <Product />

    </>
}