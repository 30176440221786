import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class EmailService {

  static sendEmail(mailData) {

    emailjs.send('service_b8wb8i5', 'template_ta91lod', mailData, 'Qv8jzSqgSpxuNpxnw').then(
      (response) => {
        if (response.status === 200) {
          toast.success("Email Send Successfully");
        }
      },
      (error) => {
        console.log('FAILED...', error);
      },
    );
  }

}