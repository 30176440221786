export const Products = [
    {
        name: "Swift Dzire",
        price: "12",
        description: "Category",
        img: "swift.png",
        airportPrice: "1000",
        price8: "1800",
        price12: "2400"

    },
    {
        name: "Honda Amaze",
        price: "13",
        description: "Category",
        img: "amaze.png",
        airportPrice: "900",
        price8: "1800",
        price12: "2200"

    },
    {
        name: "Maruti Ertiga",
        price: "14",
        description: "Category",
        img: "ertiga.png",
        airportPrice: "1100",
        price8: "2000",
        price12: "2600"

    },
    {
        name: "Toyota Innova",
        price: "16",
        description: "Category",
        img: "innova.png",
        airportPrice: "1200",
        price8: "2200",
        price12: "3000"

    },
    {
        name: "Toyota Crysta",
        price: "18",
        description: "Category",
        img: "crysta.png",
        airportPrice: "1400",
        price8: "2600",
        price12: "3400"

    },
    {
        name: "Tempo Traveller",
        price: "26",
        description: "Category",
        img: "traveller.png",
        airportPrice: "2500",
        price8: "4000",
        price12: "5000"

    }
]

export const Visits = [
    {
        name: "Ayodhya",
        data: [{
            name: "Ram Janmabhoomi",
            img: "ayodhya/rammandir.png",
            description: "Translating to Ram's Birthplace, the Ram Janmabhoomi is believed to have been the birthplace of the Hindu deity, Lord Ram. According to the Indian epic Ramayan, Ram, Lord Vishnu's seventh manifestation, is said to have grown up along Ayodhya's river Sarayu. Located in the ancient city of Ayodhya, he Ram Janmabhoomi is a highly revered site for Hindu devotees."
        },
        {
            name: "Hanuman Garhi",
            img: "ayodhya/hanuman.jpg",
            description: "Located in Sai Nagar, Hanuman Garhi is a 10th-century temple dedicated to the Hindu God, Hanuman. It is one of the most important temples in Ayodhya as it is customary to visit Hanuman Garhi before visiting the Ram Temple in Ayodhya. It is believed that Lord Hanuman lived at the temple site guarding Ayodhya."
        },
        {
            name: "Kanak Bhawan",
            img: "ayodhya/kanakbhawan.jpg",
            description: "The Kanak Bhawan is established towards the northeastern corner of the Ram Janmabhoomi in Tulsi Nagar. Constructed in 1891, this temple is also known as Sone-ka-Ghar. It is a holy site dedicated to the Hindu deity Lord Rama and his wife, Goddess Sita. Kanak Bhawan, also meaning Golden Palace, cites three golden-crowned idols of the two gods under a silver roof in the sanctum santorum (Garbagriha)."
        },
        {
            name: "Nageshwarnath Temple",
            img: "ayodhya/nageswarnath.jpg",
            description: "Established in the name of the local deity, Lord Nageshwarnath, the Nageshwarnath Temple is located adjacent to the Theri Bazaar in Ayodhya. It is believed to have been set up by Kush or Kusha, Lord Rama's son. Although this sacred site continued to be in good maintenance since 750 AD, the current temple is said to have been reconstructed in 1750 by Safar Jung's minister, Naval Rai."
        },
        {
            name: "Gulab Bari",
            img: "ayodhya/gulabbari.jpg",
            description: "Also known as the Garden of Roses, the Gulab Bari is situated in Vaidehi Nagar. It is the tomb of the third Nawab of Faizabad (Oudh or Awadh), Nawab Shuja-ud-Daula and his parents. The 18th-century structure of the Gulab Bari brings out pure Nawab-styled architecture alongside a vast array of rose species in addition to fountains and lush greenery."
        },
        {
            name: "Treta Ke Thakur",
            img: "ayodhya/tretakethakur.jpg",
            description: "Situated along the Naya Ghat of Ayodhya, the Treta Ke Thakur Temple houses numerous idols including that of Lord Ram, Sita, Lakshman, Hanuman, Bharat and Sugreev. These statues are said to have been sculpted out of a single black sandstone.Treta Ke Thakur is believed to have been constructed 300 years into the past, by Kullu, the king of the time."
        },
        {
            name: "Choti Chawni",
            img: "ayodhya/chotichawni.jpg",
            description: "Also known as Valmiki Bhawan or Maniramdas Chawni, Choti Chawni is a magnificent structure in Ayodhya completely crafted out of white marble. A sigh of sheer beauty, this place is definitely worth a visit.The heritage caves are 34 in number, 12 to the south are Buddhist, 17 in the centre are Hindu and 5 in the north are Jain, therefore making it a significant and elaborate architectural brilliance."
        },
        {
            name: "Sita Ki Rasoi",
            img: "ayodhya/sitakirasoi.jpg",
            description: "Situated on the north-western side of the Ram Janmanhoomi in Ayodhya's Rajkot, Sita ki Rasoi is believed to be an ancient kitchen used by goddess Sita herself. Built quite close to the Ram Janmabhoomi, this sacred site is now a temple housing some exhibit vessels. One among the two kitchens revered in Sita's name, this Sita Ki Rasoi is a basement kitchen."
        },
        {
            name: "Tulsi Smarak Bhawan",
            img: "ayodhya/tulsismarakbhawan.jpg",
            description: "Established in the memory of 16th-century saint-poet Goswami Tulsidas, the Tulsi Smarak Bhawan is believed to have been the place where Tulsidas composed the Ramcharita. Located on the eastern end of the National Highway at Rajgang Crossing in Ayodhya, the Smarak was built in 1969, Sri Vishwanath Das, the Governor of Uttar Pradesh of the time."
        },
        {
            name: "Bahu Begum Ka Maqbara",
            img: "ayodhya/bahubegam.jpg",
            description: "Located on the Maqbara Road in the town of Faizabad, Bahu Begum ka Makbara is a popularly known as the “Taj Mahal of the East”. The unique mausoleum dedicated to the Queen Bride Begum Unmatuzzohra Bano, wife and queen of Nawab Shuja- ud- Daula, is the tallest monument in all of Faizabad and is famous for its non-Mughal architectural brilliance."
        },
        {
            name: "Raja Mandir",
            img: "ayodhya/rajamanidr.jpg",
            description: "Located on the banks of river Ghaggar (Sarayu) in Guptar Ghar, Raja Mandir in Faizabad has been associated with numerous mythological stories time and again. The temple houses stunningly carved idols of many Hindu Gods and Goddess, which are ornamentally clad in silken fabrics and rich jewellery. The exquisite construction of the temple reflects the brilliance of Hindu architecture. Once known for its association with Lord Sri Ram, the ruler of Ayodhya; the shrine is now a regular temple with statues of numerous deities."
        }
        ]

    },
    {
        name: "Allahabad",
        data: [{
            name: "Prayag Kumbh Mela",
            img: "allahabad/kumbhmela.jpg",
            description: "The Kumbh Mela, widely regarded as the largest gathering of pilgrims in the world, is a very important event for Hinduism. Held in at four different areas, a great number of Hindus arrive at the fair to bathe in the holy rivers, a ritual meant to cleans them of sin and bring purity to their lives. The Kumbh Mela is held every three years in a rotation between Haridwar, Allahabad, Nashik, and Ujjain, thus occurring once every twelve years at each destination."
        },
        {
            name: "Triveni Sangam",
            img: "allahabad/trivenisangam.jpg",
            description: "One of the holiest places in Central India, Triveni Sangam is located about 7 kilometres away from Civil Lines in Allahabad (Prayagraj). It is the meeting point of three rivers - Ganga, Yamuna, and Saraswati (which is a mythical river, supposed to have dried up more than 4,000 years ago). It is one of the places where the Kumbh Mela is held once every 12 years. The exact date of the Mela is determined according to the Hindu calendar i.e. the Panchanga."
        },
        {
            name: "Khusro Bagh",
            img: "allahabad/khusarobagh.jpg",
            description: "Lying at Lukarganj, Khusro Bagh is one of the most popular tourist destinations in Allahabad. The walled bracketing of the Khusro Bagh is a stunning remnant of the Mughal architecture.It houses three sandstone tombs of the Jehangir family; his wife; Shah Begum, his oldest son; Khusrau Mirza and his daughter; Sultan Nithar Begum. Most of the design of the place is credited to Aqa Reza, an artist on Jehangir's court"
        },
        {
            name: "Anand Bhawan",
            img: "allahabad/anandbhawan.jpg",
            description: "Anand Bhawan is the former residence of Nehru family which has now been transformed into a museum showcasing various artefacts and articles of the era of Independence movement in India. The double-storey mansion was personally designed by Motilal Nehru.When the prior residence of Nehru's family Swaraj Bhavan started to be used as an office of Indian National Congress, a new Nehru residence was designed by Motilal Nehru, a notable independence movement leader, and politician."
        },
        {
            name: "Allahabad Museum",
            img: "allahabad/allahabadmuseum.jpg",
            description: "Situated inside the famous Chandrashekar Azad Park, the Allahabad Museum is one of the national-level museums of India. It gives an insight into the history, culture, heritage and independence movement of India through the amazing displays of artefacts related to the art, history, archaeology, architecture, environment and literature.The main highlights of the Allahabad Museum are the rock sculptures, the miniature paintings from Rajasthan, terracotta from Kaushambi, literary and artwork from Bengal School of Art"
        },
        {
            name: "Allahabad Fort",
            img: "allahabad/allahabadfort.jpg",
            description: "Allahabad Fort is a splendid work of architecture that was built during the reign of Akbar, the Mughal Emperor in 1583. The marvellous structure is located on the banks of the confluence of the Rivers Ganga and Yamuna and is well-known for being the largest fort ever built by Akbar.This famous attraction draws thousands of tourists from all over the world not only for its historical importance but also for its architectural magnificence. However, one should note that quite, unfortunately, access to Allahabad Fort is generally closed to the common public"
        },
        {
            name: "Hanuman Mandir",
            img: "allahabad/hanumanmandir.jpg",
            description: "Colloquially known as Bade Hanuman Mandir, Hanuman Temple is located at Sangam area of Allahabad. Constructed underground, with a leaning posture of a 20-feet long and 8-feet wide deity of Hanuman, this temple is one of its own kind.The shrine is a popular pilgrim spot in the city and stays especially busy on Tuesdays and Saturdays. Located just outside the Allahabad Fort, the Hanuman Mandir is also known as the Lete Hue Hanuman Temple."
        },
        {
            name: "Chandrashekhar Azad Park",
            img: "allahabad/chandrasekharajadpark.jpg",
            description: "Originally known as Alfred Park during the British colonial era, Chandrashekhar Azad Park is better known as the Company Garden. Located in George Town of Prayagraj, this park was established in 1870 as a significant mark of the arrival of Prince Alfred into the city. Company Gardens was further renamed as the Chandrashekar Azad Park in 1931 after the latter died as a martyr here."
        },
        {
            name: "All Saints Cathedral",
            img: "allahabad/allsaintchathendral.jpg",
            description: "Built in the late 19th century, All Saints Cathedral or Patthar Girja is a spectacular Anglican Christian Church on M G Marg, Allahabad. One of the beautiful churches in the state, it is thronged by tourists and pilgrims alike.All known as the 'Church of Stone', All Saints Cathedral was formerly founded in 1871 by Lady Muir Elizabeth Huntly Wemyss. Post its consecration in 1887, the church was further completed in 1891."
        },
        {
            name: "Alopi Devi Mandir",
            img: "allahabad/alopidevimandir.png",
            description: "Located at Alopibagh, near the holy Sangam (confluence of Ganga, Yamuna and Saraswati) in Allahabad, Alopi Devi Mandir is an unconventional temple revered by Hindu faith.The Alopi Devi Mandir does not have any presiding deity but a wooden chariot called a doliê which is mostly worshipped by the devotees of Lord Shiva."
        }
        ]

    },
    {
        name: "Varanasi",
        data: [
            {
                name: "Shri Kashi Vishwanath Temple",
                img: "varanasi/vishwanathtemple.jpg",
                description: "Situated on the western bank of holy river Ganga in Varanasi, Kashi Vishwanath Temple is one of the 12 Jyotirlingas or temples dedicated to Lord Shiva. The main deity of Kashi Vishwanath Temple is Lord Shiva, also known as Vishwanatha or Vishweshwarar meaning 'the ruler of the universe'. The city of Varanasi, the cultural capital of India, is thus known as the city of Lord Shiva. The temple has 800 kg of gold plating on its tower."
            }
            ,
            {
                name: "Dashashwamedh Ghat",
                img: "varanasi/dashaswamedh.jpg",
                description: "Dashashwamedh Ghat, situated on the Ganges River in Varanasi, holds immense religious significance and cultural vibrancy. Renowned for its daily Ganga Aarti, it offers a mesmerizing experience with spiritual rituals, pilgrim activities, and a lively atmosphere. Every evening, Dashashwamedh Ghat hosts the renowned Ganga Aarti, a spiritual ceremony featuring priests conducting rituals with brass lamps and hymns. This captivating spectacle attracts large crowds, creating a divine ambiance."
            }
            ,
            {
                name: "Assi Ghat",
                img: "varanasi/assighat.jpg",
                description: "The Assi Ghat is placed at the confluence of the Rivers Assi and Ganga and is famous for the large Shiva Lingam installed under a peepal tree. It has immense religious importance and has been mentioned in the Puranas and various legends as well. Assi Ghat is the heart of Varanasi and the locals, as well as, the tourists flock there to enjoy the amazing view of the sunset and sunrise at the Ganges."
            },
            {
                name: "Sankat Mochan Hanuman Temple",
                img: "varanasi/sankatmochan.jpg",
                description: "The Sankat Mochan Hanuman Temple is situated by the River Assi and was built in the 1900s by the freedom fighter Pandit Madan Mohan Malviya. It is dedicated to Lord Rama and Hanuman. Varanasi is always related to Sankat Mochan Temple and is an essential part of this holy city. Every person visiting Varanasi visits this temple and pay their respects to Hanuman."
            },
            {
                name: "Tulsi Manas Mandir",
                img: "varanasi/tulsimanasmandir.jpg",
                description: "Constructed in 1964, this temple is dedicated to Lord Rama and is named after the saint poet Tulsi Das. It displays the Shikhara style of architecture and exhibits various inscriptions from the Ram Charit Manas on the walls of the temple. Various episodes of Ramayana have also been depicted on the upper storey of the temple in the form of carvings. The temple is a must visit during the months of Saawan (July - August) when it opens up a special display of puppets, related to Ramayana, and is a fun experience for everyone."
            },
            {
                name: "Ramnagar Fort",
                img: "varanasi/ramnagarfort.jpg",
                description: "Located on the Eastern banks of River Ganga, opposite to Tulsi Ghat, Ramnagar Fort is a stunning historical monument in Varanasi. It was built by Raja Balwant Singh in 1750 according to the Mughal style of architecture. Even though the system of Kings was abolished in the region, the current Maharaja, Pelu Bhiru Singh, resides in the Fort. It reflects a blend of Mughal and Indian architectural styles, featuring ornate structures and intricate carvings."
            },
            {
                name: "New Vishwanath Temple, BHU",
                img: "varanasi/bhuvishwanath.jpg",
                description: "The New Vishwanath Temple is every pious man's go-to destination to feel overwhelmed by the presence and power of the great Lord Shiva. Not only is it located in the heart of the cultural capital of India - Varanasi, but its divinity is multiplied by the holy river Ganga that flows by its side. Banaras Hindu University is hence home to one of the biggest tourist attractions in the city of Varanasi, the grand New Vishwanath Temple. Visiting this temple gives the worshipper the space to put himself in the hands of God, at his disposition, and listen to the voice in his heart. "
            },
            {
                name: "Durga Temple",
                img: "varanasi/durgatemple.jpg",
                description: "Durga Temple, nestled in the vibrant city of Varanasi, is a unique Hindu shrine dedicated to Goddess Durga built in the 18th century. Renowned for its vivid red color and intricate architecture, the temple exudes a spiritual aura. Devotees visit to seek blessings, especially during the festive fervor of Navaratri, when the temple comes alive with religious celebrations. It houses an imposing idol of Goddess Durga and is one of the main temples in Varanasi."
            },
            {
                name: "Banaras Hindu University",
                img: "varanasi/bhu.jpg",
                description: "Established by Madan Mohan Malviya in 1916, Banaras Hindu University is a 5.3 square kilometre campus in the city. It has about 30,000 students residing in the campus and is the largest residential university in the continent. The University has a long list of notable alumni and faculty. The Indo-Gothic architecture and the sprawling lawns add to the beauty of the place."
            },
            {
                name: "Nepali Temple",
                img: "varanasi/nepalitemple.jpg",
                description: "One of the oldest temples in the city, Nepali Temple is a 19th century shrine dedicated to Lord Shiva. It was established by the King of Nepal and is a spitting image of the Pashupatinath Temple located in Kathmandu. The traditional architecture of the structure made of stone, terracotta and wood carvings attracts visitors in large numbers."
            },
            {
                name: "Batuk Bhairav Mandir",
                img: "varanasi/batukbhairav.jpg",
                description: "Famous for being a place of worship for Aghoris and Tantriks, Batuk Bhairav Mandir is considered to be of immense religious importance. The temple is dedicated to Batuk Bhairav who was an incarnation of Lord Shiva. An interesting feature of the temple is the sacred Akhand Deep which is believed to be burning for ages. The oil from this lamp is said to have healing powers."
            },
            {
                name: "Tibetan Temple",
                img: "varanasi/tibitiantemple.jpg",
                description: "A shrine with stunning Tibetan Architecture, Tibetan Temple was where Lord Buddha taught his disciples the four truths of life. The edifice has a beautiful statue of Shakyamuni, prayer wheels and is decorated with traditional Buddhist paintings also called Thangksa. The unique feature of the temple is the prayer wheels that release strips of paper with chants written on them when rotated clockwise."
            },
            {
                name: "Bharat Mata Mandir",
                img: "varanasi/bharatmatamandir.jpg",
                description: "Bharat Mata Mandir is a unique shrine dedicated to our country, Mother India. The temple does not have any deity, but a relief map of the country carved in marble. The temple was the brainchild of Babu Shiv Prasad Gupta, a freedom fighter. It was built in 1936 and inaugurated by Mahatma Gandhi and has ever since been the only one dedicated to a country in the World."
            },
            {
                name: "ISKCON Varanasi",
                img: "varanasi/iscon.jpg",
                description: "ISKCON was established by Bhaktivedanta Swami Prabhupada to promote Krishna Consciousness and educate people with the teachings of Lord Krishna as per the Bhagwad Geeta. Regular poojas, kirtanas and chanting sessions are very popular here. Everyone is welcome to join these sessions without discrimination."
            }

        ]
    },
    {
        name: "Gaya",
        data: [
            {
                name: "Mahabodhi Temple",
                img: "gaya/mahabodhi.jpg",
                description: "The Mahabodhi temple, also called the 'Great Awakening Temple', is a UNESCO World Heritage Site located in Bodhgaya, Bihar. It is a Buddhist temple that marks the location where the Lord Buddha is said to have attained enlightenment. Lord Buddha holds a very significant place in the religious history of India as he is believed to be the 9th and the most recent incarnation of Lord Vishnu to have walked the earth. The temple spreads over an area of a whopping 4.8 hectares and is 55 meters tall."
            },
            {
                name: "Bodhi Tree",
                img: "gaya/bodhitree.jpg",
                description: "The Bodhi Tree, located 100 kilometres to the south of Patna in the north Indian state of Bihar, is perhaps the most prominent and respected sacred places in Buddhism because of the rich historical significance attached to it. It is under this tree that Prince Siddhartha Gautama, the spiritual teacher who later became to be known as the Buddha, attained enlightenment. It is believed that Prince Siddhartha had to sit and meditate under this tree for seven days. Later on, a shrine known as Animisalocana Cetiya was erected on the spot where he had sat down."
            },
            {
                name: "Brahmayoni Temple",
                img: "gaya/brahmayonitemple.jpg",
                description: "Devotees who wish to visit this temple must go through a grueling climb of 424 high stone steps to reach the top of the hill, atop which lies this temple.Two caves namely Brahmayoni and Matreoni are also situated on the hill along with an ancient temple of Astabhujadevi. Brahmayoni hill was the place where Buddha preached the fire-sermon to a thousand former fire worshipping ascetics and it is said that each ascetic became enlightened after hearing his teachings."
            },
            {
                name: "Thai Monastery",
                img: "gaya/thaimonasty.jpg",
                description: "Well known for its curved roof covered with exquisite tiles of gold, the Thai Monastery houses a bronze statue of Buddha and another recently erected 25m high statue in the garden."
            },
            {
                name: "Royal Bhutan Monastery",
                img: "gaya/royalbhutanmonastry.jpg",
                description: "The Royal Bhutan Monastery has been named so, as it was built by the King of Bhutan as a dedication to Lord Buddha. Clay carvings are seen on the interior walls of the monastery that depict different aspects of the Buddhist culture."
            },
            {
                name: "Muchalinda Lake",
                img: "gaya/muchalindalake.jpg",
                description: "A popular site at Bodh Gaya, the Muchalinda Lake is located next to the main temple. It is associated with a mythological tale stating that Lord Buddha was protected from the storm by Snake King Muchalinda."
            },
            {
                name: "Cankamana",
                img: "gaya/chankamana.jpg",
                description: "Situated along the Bodhi Temple, Cankamana is a holy shrine featuring a carving of Lord Buddha's feet into the black stone lotuses."
            },
            {
                name: "Chinese Temple",
                img: "gaya/chinesetemple.jpg",
                description: "Chinese Temple was constructed by Buddhist monks. It displays beautiful Chinese artwork with a statue of Lord Buddha within the sanctum."
            },
            {
                name: "Great Buddha Statue",
                img: "gaya/greatbuddha.jpg",
                description: "This statue of Lord Buddha is the tallest in India and was instated by the XIV Dalai Lama in 1989. It is a meditating Buddha resting on a giant lotus, constructed using intricately carved sandstone and red granite."
            },
            {
                name: "Indosan Nippon Japanese Temple",
                img: "gaya/nippontemple.jpg",
                description: "An outstanding example of Buddhist culture and Japanese architecture, this temple was built in 1972 with the help of International Buddhist communities.The structure of the temple is carved out of wood and it looks like a Japanese shrine. It also showcases a lot of Japanese paintings narrating events from the life of Gautam Buddha."
            }
        ]

    }
]