import { useState } from "react";
import { EmailService } from "../Service/EmailService";

export default function ContactUs() {
    const [formData, setFormData] = useState({
        receiver: 'Booking@gangescab.com',
        name: '',
        contact: '',
        email: '',
        query: '',
    });

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            sender: formData.name,
            receiver: formData.receiver,
            message: "Contact: " + formData.contact + "\n Email: " + formData.email + "\n Customer's Query: " + formData.query
        }

        await EmailService.sendEmail(data);

        setFormData({
            receiver: 'Booking@gangescab.com',
            name: '',
            contact: '',
            email: '',
            query: '',
        })
    };

    return <section className="text-center">

        <div className="p-5 bg-image" style={{
            backgroundImage: "url('https://mdbootstrap.com/img/new/textures/full/171.jpg')", height: "300px"
        }}></div>


        <div className="card mx-4 mx-md-5 shadow-5-strong bg-body-tertiary" style={{
            marginTop: "-100px", backdropFilter: "blur(30px)"
        }}>
            <div className="container my-5">
                <div className="row gy-3 gy-md-4 gy-lg-0 align-items-lg-center">
                    <div className="col-12 col-lg-6 col-xl-5 d-flex flex-column text-start" style={{ gap: "5vh" }}>
                        <div><h1>Ganges Cab</h1></div>
                        <div className="container d-flex">
                            <img decoding="async" className="eb-infobox-image" src="https://static.live.templately.com/woocommerce/2023/03/7a31df71-lmap.png" alt="macbook" />
                            <div className="px-4">
                                <h3>Address:</h3>
                                <span>VARANASI UTTAR PRADESH</span>
                            </div>
                        </div>

                        <div className="container d-flex">
                            <img decoding="async" className="eb-infobox-image" src="https://static.live.templately.com/woocommerce/2023/03/137a9a8c-phone.png" alt="macbook" />
                            <div className="px-4">
                                <h3>Contact:</h3>
                                <span>+91 9453112256</span>
                                <span> / +91 7007163607</span>
                            </div>
                        </div>
                        <div className="container d-flex">
                            <img decoding="async" className="eb-infobox-image" src="https://static.live.templately.com/woocommerce/2023/03/d24fc8d2-email.png" alt="macbook" />
                            <div className="px-4">
                                <h3>Email:</h3>
                                <a href="mailto:booking@gangescab.com" className="nav-link mr-3"> Booking@gangescab.com</a>

                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 col-xl-7">
                        <div className="row justify-content-xl-center">
                            <div className="col-lg-10">
                                <h2 className="fw-bold mb-5">Send Us Your Query</h2>
                                <form className="py-2 px-4 d-flex flex-column text-start" style={{ gap: "3vh" }} >
                                    <div className="form-group">
                                        <label htmlFor="name">Your name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="name"
                                            placeholder="Enter your name"
                                            value={formData.name}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="contact">Contact</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="contact"
                                            placeholder="Contact Number"
                                            value={formData.contact}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="email"
                                            placeholder="Enter Your Email"
                                            value={formData.email}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="query">Your Query</label>
                                        <textarea
                                            type="text"
                                            className="form-control"
                                            rows={6}
                                            id="query"
                                            placeholder="Enter Your Message"
                                            value={formData.query}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <button onClick={handleSubmit} className="btn btn-primary">Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
}