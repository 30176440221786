import { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import { Visits } from "../assets/data/WebContent"

export default function PopularVisits() {
    const params = useParams();
    const [currentCity, setCurrentCity] = useState('');

    useEffect(() => {
        const currentCity = Visits.find(e => (params.cityname === e.name));
        setCurrentCity(currentCity);
    }, [params.cityname])

    return <section>
        <div className="p-5 bg-image" style={{
            backgroundImage: "url('https://mdbootstrap.com/img/new/textures/full/171.jpg')", height: "300px"
        }}></div>

        <div className="card mx-4 mx-md-5 shadow-5-strong bg-body-tertiary" style={{
            marginTop: "-100px", backdropFilter: "blur(30px)"
        }}>

            <div className="container mb-5">
                <div className="row justify-content-md-center">
                    <div className="col-12 col-md-10 col-lg-8 col-xl-7">
                        <h2 className="display-5 mb-4 mt-4 text-center">Pupular Visits In {currentCity.name}</h2>
                        <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
                    </div>
                </div>
                <div className="row g-4">
                    {currentCity && currentCity.data.map((item, index) =>
                        <div className="col-md-6 col-sm-6 col-lg-4" key={index}>
                            <div className="card">
                                <div style={{ height: "50vh" }}>
                                    <img src={require(`../assets/images/${item.img}`)} className="w-100"
                                        style={{ height: "inherit" }}
                                        alt="Hollywood Sign on The Hill" />
                                </div>
                                <div className="card-body">
                                    <h5 className="card-title">{item.name}</h5>
                                    <p className="card-text">
                                        {item.description}
                                    </p>
                                </div>
                                <div className="d-flex justify-content-center mb-3">
                                    <a href="/contact"><button className="btn btn-primary">Book Cab</button></a></div>
                            </div>
                        </div>)

                    }
                </div>
            </div>
        </div>
    </section>
}