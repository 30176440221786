import { Products } from "../assets/data/WebContent"

export default function Product() {
    return <>
        <section >
            <div className="text-center container py-5">
                <h2 className="display-5 mb-5 text-center">Our Rental Vehicles </h2>
                {/* <h4 className="mt-4 mb-5"><strong>Our Rental Vehicles</strong></h4> */}

                <div className="row">
                    {Products.map((item, index) =>
                        <div className="col-lg-4 col-md-6 col-sm-6 mb-4 " key={index}>
                            <div className="card ">
                                <div className="bg-image hover-zoom ripple ripple-surface ripple-surface-light product-image "
                                    data-mdb-ripple-color="light">
                                    <img src={require(`../assets/images/${item.img}`)}
                                        className="w-100" />

                                </div>
                                <div className="card-body">
                                    <h5 className="card-title mb-3" style={{ fontSize: "x-large" }}>{item.name}</h5>

                                    <div className="row align-items-center">
                                        <div className="col-md-4 col-sm-4 col-4"> <hr></hr></div>
                                        <div className="col-md-4 col-sm-4 col-4 d-flex justify-content-center"> &#8377;
                                            <h2 style={{ color: "#ffc107c2" }}>{item.price}</h2><div className="d-flex align-items-center">/km</div></div>
                                        <div className="col-md-4 col-sm-4 col-4"> <hr></hr> </div>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="col-md-12 col-sm-12 d-flex align-items-center">
                                            <svg style={{ color: "#ffc107", marginRight: "1vh" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-airplane-fill" viewBox="0 0 16 16">
                                                <path d="M6.428 1.151C6.708.591 7.213 0 8 0s1.292.592 1.572 1.151C9.861 1.73 10 2.431 10 3v3.691l5.17 2.585a1.5 1.5 0 0 1 .83 1.342V12a.5.5 0 0 1-.582.493l-5.507-.918-.375 2.253 1.318 1.318A.5.5 0 0 1 10.5 16h-5a.5.5 0 0 1-.354-.854l1.319-1.318-.376-2.253-5.507.918A.5.5 0 0 1 0 12v-1.382a1.5 1.5 0 0 1 .83-1.342L6 6.691V3c0-.568.14-1.271.428-1.849" />
                                            </svg>
                                            Airport Transfer =  <span style={{ color: "#4a8f0ceb", fontWeight: "500" }}>
                                                &#8377;{item.airportPrice}</span></div><hr ></hr>
                                        <div className="col-md-12 col-sm-12 d-flex align-items-center" >
                                            <svg style={{ color: "#ffc107", marginRight: "1vh" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-hourglass-bottom" viewBox="0 0 16 16">
                                                <path d="M2 1.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1-.5-.5m2.5.5v1a3.5 3.5 0 0 0 1.989 3.158c.533.256 1.011.791 1.011 1.491v.702s.18.149.5.149.5-.15.5-.15v-.7c0-.701.478-1.236 1.011-1.492A3.5 3.5 0 0 0 11.5 3V2z" />
                                            </svg>
                                            8 Hours (max 80km) = <span style={{ color: "#4a8f0ceb", fontWeight: "500" }}>
                                                &#8377;{item.price8}</span></div><hr></hr>
                                        <div className="col-md-12 col-sm-12 d-flex align-items-center">
                                            <svg style={{ color: "#ffc107", marginRight: "1vh" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-hourglass-top" viewBox="0 0 16 16">
                                                <path d="M2 14.5a.5.5 0 0 0 .5.5h11a.5.5 0 1 0 0-1h-1v-1a4.5 4.5 0 0 0-2.557-4.06c-.29-.139-.443-.377-.443-.59v-.7c0-.213.154-.451.443-.59A4.5 4.5 0 0 0 12.5 3V2h1a.5.5 0 0 0 0-1h-11a.5.5 0 0 0 0 1h1v1a4.5 4.5 0 0 0 2.557 4.06c.29.139.443.377.443.59v.7c0 .213-.154.451-.443.59A4.5 4.5 0 0 0 3.5 13v1h-1a.5.5 0 0 0-.5.5m2.5-.5v-1a3.5 3.5 0 0 1 1.989-3.158c.533-.256 1.011-.79 1.011-1.491v-.702s.18.101.5.101.5-.1.5-.1v.7c0 .701.478 1.236 1.011 1.492A3.5 3.5 0 0 1 11.5 13v1z" />
                                            </svg>
                                            12 Hours (max 200km) = <span style={{ color: "#4a8f0ceb", fontWeight: "500" }}>
                                                &#8377;{item.price12}</span></div><hr></hr>
                                        <div className="col-md-12 col-sm-12 d-flex align-items-center">
                                            <svg style={{ color: "#ffc107", marginRight: "1vh" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-sign-merge-right-fill" viewBox="0 0 16 16">
                                                <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098zM8.75 6v1q.211.451.588.95c.537.716 1.259 1.44 2.016 2.196l-.708.708-.015-.016c-.652-.652-1.33-1.33-1.881-2.015V12h-1.5V6H6.034a.25.25 0 0 1-.192-.41l1.966-2.36a.25.25 0 0 1 .384 0l1.966 2.36a.25.25 0 0 1-.192.41z" />
                                            </svg>
                                            Outstation (min 250km) = <span style={{ color: "#4a8f0ceb", fontWeight: "500" }}>
                                                &#8377;{item.price}/km</span></div> <hr></hr>
                                        <div className="col-md-12 col-sm-12 d-flex align-items-center">
                                            <svg style={{ color: "#ffc107", marginRight: "1vh" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-moon-stars-fill" viewBox="0 0 16 16">
                                                <path d="M6 .278a.77.77 0 0 1 .08.858 7.2 7.2 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277q.792-.001 1.533-.16a.79.79 0 0 1 .81.316.73.73 0 0 1-.031.893A8.35 8.35 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.75.75 0 0 1 6 .278" />
                                                <path d="M10.794 3.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387a1.73 1.73 0 0 0-1.097 1.097l-.387 1.162a.217.217 0 0 1-.412 0l-.387-1.162A1.73 1.73 0 0 0 9.31 6.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387a1.73 1.73 0 0 0 1.097-1.097zM13.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.16 1.16 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.16 1.16 0 0 0-.732-.732l-.774-.258a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732z" />
                                            </svg>
                                            Night Charges (after 9pm) = <span style={{ color: "#4a8f0ceb", fontWeight: "500" }}>
                                                &#8377;300<span style={{ color: "red" }}>*</span></span></div> <hr></hr>
                                    </div>
                                    {/* <h6 className="mb-3">{item.price}</h6> */}
                                    <a href="/contact"><button className="btn btn-primary">Book Now </button></a>
                                </div>
                            </div>
                        </div>)

                    }
                </div>
            </div>
        </section>
    </>
}