export default function Footer() {

    return <div>
        <footer className="text-center text-lg-start text-dark pt-1" style={{ backgroundColor: "#ECEFF1" }} >
            <div className="container text-center text-md-start mt-5" >
                <div className="row mt-3">
                    <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                        <h6 className="text-uppercase fw-bold">Ganges Cab</h6>
                        <hr className="mb-4 mt-0 d-inline-block mx-auto" style={{ width: "60px", backgroundColor: "#7c4dff", height: "2px" }} />
                        <p>

                            Welcome to Ganges Cab, your reliable partner for comfortable and
                            convenient transportation. Whether you need a quick ride across town,
                            an airport transfer, or a luxury vehicle for a special occasion,
                            Ganges Cabs has you covered.
                        </p>
                    </div>
                    <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                        <h6 className="text-uppercase fw-bold">All UP Tours</h6>
                        <hr className="mb-4 mt-0 d-inline-block mx-auto" style={{ width: "60px", backgroundColor: "#7c4dff", height: "2px" }} />
                        <p>
                            <a href="/visits/Ayodhya" className="nav-link">Ayodhya</a>
                        </p>
                        <p>
                            <a href="/visits/Allahabad" className="nav-link">Allahabad</a>
                        </p>
                        <p>
                            <a href="/visits/Varanasi" className="nav-link">Varanasi</a>
                        </p>
                        <p>
                            <a href="/visits/Gaya" className="nav-link">Gaya</a>
                        </p>
                    </div>

                    <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                        <h6 className="text-uppercase fw-bold">Our Cabs</h6>
                        <hr className="mb-4 mt-0 d-inline-block mx-auto" style={{ width: "60px", backgroundColor: "#ffc107", height: "2px" }} />
                        <p>
                            Swift Dzire
                        </p>
                        <p>
                            Honda Amaze
                        </p>
                        <p>
                            Maruti Ertiga
                        </p>
                        <p>
                            Toyota Innova
                        </p>
                    </div>

                    <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                        <h6 className="text-uppercase fw-bold">Contact</h6>
                        <hr className="mb-4 mt-0 d-inline-block mx-auto" style={{ width: "60px", backgroundColor: "#7c4dff", height: "2px" }} />
                        <p><i className="fas fa-home mr-3"></i> Varanasi Uttar Pradesh, India</p>
                        <a href="mailto:booking@gangescab.com" className="nav-link mr-3"> <p> Booking@gangescab.com</p></a>
                        <p><i className="fas fa-phone mr-3"></i> +91 9453112256</p>
                        <p><i className="fas fa-print mr-3"></i> +91 7007163607</p>
                    </div>
                </div>
            </div>
            <div className="p-3 d-flex justify-content-center" style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }} >
                <span>© 2024 Copyright & Developed by: &nbsp;</span>
                <span><a className="nav-link" href="https://zapweb.in/"> Zapweb </a></span>
            </div>
        </footer>
    </div>
}