import { useEffect, useState } from "react";
import logo from "../assets/images/logo.png"

export default function TopHeader() {

    const [offset, setOffset] = useState(0);

    useEffect(() => {

        const onScroll = () => setOffset(window.scrollY);
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    return <>
        <nav className={offset < 100 ? "navbar navbar-expand-lg fixed-top navbar-scroll" : "navbar navbar-expand-lg fixed-top navbar-scroll navber-container"}>
            <div className="container">
                <a className="navbar-brand d-flex" href="/">
                    <img className={offset < 100 ? "img-fluid logo-container" : "logo-container-scrolled"}
                        src={logo}></img>
                    <div><span className={offset < 100 ? "" : "header-name"}> Ganges</span><span className={offset < 100 ? "header-name" : "header-name2"}>Cab</span></div></a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5" />
                    </svg>
                </button>
                <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                    <ul className="navbar-nav " style={{ gap: "4%" }} >
                        <li className="nav-item">
                            <a className={offset < 100 ? "nav-link" : "nav-link header-name"} href="/">Home</a>
                        </li>
                        <li className="nav-item">
                            <a className={offset < 100 ? "nav-link" : "nav-link header-name"} href="/about">About</a>
                        </li>
                        <li className="nav-item">
                            <a className={offset < 100 ? "nav-link" : "nav-link header-name"} href="/contact">Contact</a>
                        </li>
                    </ul>
                    <ul className="navbar-nav d-flex flex-row">
                        <li className="nav-item me-3 me-lg-0">
                            <a className="nav-link" href="#!">
                                <i className="fas fa-shopping-cart"></i>
                            </a>
                        </li>
                        <li className="nav-item me-3 me-lg-0">
                            <a className="nav-link" href="#!">
                                <i className="fab fa-twitter"></i>
                            </a>
                        </li>
                        <li className="nav-item me-3 me-lg-0">
                            <a className="nav-link" href="#!">
                                <i className="fab fa-instagram"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </>
}